<template>
  <div>
    <!-- 备份与恢复 -->
    <!-- <div v-if="flag == 1"> -->
    <!-- <Nav /> -->
    <!-- <el-button-group class="btn"> -->
    <!-- <el-button type="primary" size="small" @click="backups">备份当前配置</el-button> -->
    <!-- <el-button type="primary" size="small" @click="Import">导入</el-button> -->
    <!-- <el-button style="margin-left: 1px" type="info" size="small" class="delBtn" v-if="delarr == ''" disabled>删除 -->
    <!-- </el-button> -->
    <!-- <el-button style="margin-left: 1px" type="primary" size="small" class="delBtn" @click="delArr(delarr)" v-else>删除 -->
    <!-- </el-button> -->
    <!-- </el-button-group> -->
    <!-- <el-row :gutter="16" class="mt16" style=" margin: 0 10px;"> -->
    <!-- <el-table :cell-style="{padding:'3px 0px'}" :header-row-style="{height:'30px'}" size="mini" stripe ref="multipleTable" :data="tableData" class="tableClass" -->
    <!-- tooltip-effect="dark" style="width: 100%;"  @selection-change="delobj"> -->
    <!-- <el-table-column header-align="left" align="left" type="selection" width="60"> -->
    <!-- </el-table-column> -->
    <!-- <el-table-column prop="versionName" label="备份时间" :show-overflow-tooltip="true" width="320"> -->
    <!-- <template v-slot="{ row }"> -->
    <!-- {{ row.versionName }} -->
    <!-- </template> -->
    <!-- </el-table-column> -->
    <!-- <el-table-column label="操作"> -->
    <!-- <template v-slot="{ row }"> -->
    <!-- <img @click="edit(row)" title="编辑" src="@/assets/icon_write.png" alt="" -->
    <!-- style="width: 15px; height: 15px; cursor: pointer" /> -->
    <!-- <img @click="del(row.id)" title="删除" src="@/assets/icon_del.png" alt="" style=" -->
    <!-- width: 15px; -->
    <!-- height: 15px; -->
    <!-- margin-left: 5px; -->
    <!-- cursor: pointer; -->
    <!-- " /> -->
    <!-- </template> -->
    <!-- </el-table-column> -->
    <!-- </el-table> -->
    <!-- <div style="margin-left: 5px;margin-right:15px;"> -->
    <!-- <pagination :page="currentPage" :limit="pageSize" :total="total" :pageSizes="[10, 20, 30, 40]" -->
    <!-- v-on:handleChildGetList="handleParentGetList" /> -->
    <!-- </div> -->
    <!-- </el-row> -->
    <!-- </div> -->
    <!-- 导入 -->
    <!-- 保存成功提示组件 -->
    <!-- <dialog-info :dialogInfo="visibaelFlag" @handleClose="handleClose" @determine="determine" :titleInfo="titleInfo" -->
    <!-- :width="'240px'" /> -->
  </div>
</template>
<script>
import pagination from '@/components/pagination/page.vue' //分页
import dialogInfo from '@/components/promptMessage/index'
import Nav from '@/components/hearder'
export default {
  components: {
    pagination,
    dialogInfo,
    Nav
  },
  data() {
    return {
      visibaelFlag: false, //提示消息默认--关闭
      titleInfo: '', // 提示框的提示语
      delarr: [], //存放要删除的数据
      tableData: [],
      // 分页
      pageSize: 10, //每页条数,  默认10条
      total: 0, //总条数
      currentPage: 1,
      orderColume: 'versionName',
      orderRule: 'desc',
      flag: 1
    }
  },
  created() {},
  mounted() {},
  methods: {
    // 备份当前配置
    backups() {},
    // 导入
    Import() {},
    // 批量删除
    delArr(val) {},
    // 单个删除
    del(id) {},
    // 多选
    delobj(obj) {
      this.multipleSelection = obj
      this.delarr = obj
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.currentPage = page
      this.pageSize = limit
      // 调用查询方法
      this.getDatalist()
    },
    // 关闭提示框
    handleClose() {
      this.visibaelFlag = false //提示消息  关闭
    },
    //确定回调事件
    determine() {
      this.visibaelFlag = false //提示消息  关闭
    }
  }
}
</script>

<style lang='scss' scoped>
.btn {
  width: 100%;
  margin: 10px;
}
::v-deep .el-form-item {
  margin-bottom: 0px;
}
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
</style>
